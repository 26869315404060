<template>
  <div v-if="rendered">
    <div class="row">
      <div class="col-md-12">
        <vnud-card>
          <template v-slot:header>
            <div class="row">
              <div class="col-4">
                <h4 class="card-title">Taxas</h4>
              </div>
            </div>
          </template>

          <div class="table-responsive">
            <el-table stripe style="width: 100%" :data="queriedData">
              <el-table-column fixed="right" label="Produto">
                <template v-slot:default="props">
                  <div class="table-actions">
                    <p>
                      {{
                        props.row.produto == "credito_habitacao"
                          ? "Crédito Habitação"
                          : props.row.produto == "credito_consolidado"
                          ? "Crédito Consolidado"
                          : props.row.produto == "credito_pessoal"
                          ? "Crédito Pessoal"
                          : ""
                      }}
                    </p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="Taxa">
                <template v-slot:default="props">
                  <div class="table-actions">
                    <fg-input placeholder="Taxa" v-model="props.row.taxa">
                    </fg-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="Ações">
                <template v-slot:default="props">
                  <div class="table-actions">
                    <n-button
                      @click="guardar(props.row)"
                      class="black-button"
                      size="sm"
                      round
                      icon
                    >
                      <i class="fa fa-check"></i>
                    </n-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
            <div
              class="
                col-12
                d-flex
                justify-content-center justify-content-sm-between
                flex-wrap
              "
            >
              <div class="">
                <p class="card-category">
                  Mostrando <span class="boldNumbers">{{ from + 1 }}</span> até
                  <span class="boldNumbers">{{ to }}</span> de
                  <span class="boldNumbers">{{ total }}</span> leads.
                </p>
              </div>
              <n-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </n-pagination>
            </div>
          </template>
        </vnud-card>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    VnudCard,
    Table as NTable,
    AnimatedNumber,
    Progress as NProgress,
    AsyncWorldMap,
    Collapse,
    CollapseItem,
    Pagination as NPagination,
  } from "@/components";
  import api from "@/api/index";

  import {
    ElTable,
    ElTableColumn,
    ElSelect,
    ElOption,
    ElTimeSelect,
    ElDatePicker,
  } from "element-plus";
  export default {
    components: {
      VnudCard,
      NTable,
      AnimatedNumber,
      NProgress,
      AsyncWorldMap,
      NPagination,
      [ElSelect.name]: ElSelect,
      [ElOption.name]: ElOption,
      [ElTable.name]: ElTable,
      [ElTableColumn.name]: ElTableColumn,
      Collapse,
      CollapseItem,
      [ElDatePicker.name]: ElDatePicker,
      [ElTimeSelect.name]: ElTimeSelect,
    },
    data() {
      return {
        pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0,
        },
        searchQuery: "",
        propsToSearch: ["produto"],
        tableColumns: [
          {
            prop: "produto",
            label: "Produto",
          },
          {
            prop: "taxa",
            label: "Taxa",
          },
        ],
        searchedData: [],
        fuseSearch: null,
        rendered: false,
        leads: {
          conversoes: 0,
          numero: 0,
          mes: 0,
          organicas: 0,
        },
        dataTable: [],
        dateTimePickerDe: "",
        dateTimePickerAte: "",
      };
    },
    methods: {
      async inicio() {
        let res = await api.listarTaxas();
        // console.log(res);
        this.dataTable = res.sort((a, b) => {
          return a.id > b.id ? -1 : 1;
        });

        this.rendered = true;
      },
      async guardar(produto) {
        let taxa = produto.taxa.replaceAll(",", ".");
        if (!isNaN(taxa)) {
          // If it's a valid number, parse it
          taxa = parseFloat(taxa);
          var json = {
            id: produto.id,
            taxa: taxa,
          };
          var res = await api.atualizarTaxas(json);
          console.log(res);
        } else {
          alert("O numero inserido não é válido");
        }
        this.inicio();
      },
    },
    mounted() {
      this.inicio();
    },
    computed: {
      pagedData() {
        return this.dataTable.slice(this.from, this.to);
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData() {
        if (!this.searchQuery) {
          return this.pagedData;
        }
        let result = this.dataTable.filter((row) => {
          let isIncluded = false;
          for (let key of this.propsToSearch) {
            let rowValue = row[key].toString();
            if (rowValue.includes && rowValue.includes(this.searchQuery)) {
              isIncluded = true;
            }
          }
          return isIncluded;
        });
        console.log(result);
        return result.slice(this.from, this.to);
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      },
      total() {
        return this.searchedData.length > 0
          ? this.searchedData.length
          : this.dataTable.length;
      },
    },
  };
</script>
<style></style>
