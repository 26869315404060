<template>
  <div class="panel-header panel-header-lg">
    <canvas :id="headerChartID"></canvas>
  </div>
</template>

<script>
  import api from "@/api/index";
  export default {
    name: "overview-header",
    data() {
      return {
        headerChartID: "headerChart",
      };
    },
    methods: {
      criarGrafico(chartId, valores) {
        let chartColor = "#FFFFFF";
        const ctx = document.getElementById(chartId).getContext("2d");
        let gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
        gradientStroke.addColorStop(0, "#80b6f4");
        gradientStroke.addColorStop(1, chartColor);

        let gradientFill = ctx.createLinearGradient(0, 200, 0, 50);
        gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
        gradientFill.addColorStop(1, "rgba(255, 255, 255, 0.24)");
        const myChart = new Chart(ctx, {
          type: "line",
          data: {
            labels: this.labels || [
              "JAN",
              "FEV",
              "MAR",
              "ABR",
              "MAI",
              "JUN",
              "JUL",
              "AGO",
              "SET",
              "OUT",
              "NOV",
              "DEC",
            ],
            datasets: [
              {
                label: "Data",
                borderColor: chartColor,
                backgroundColor: gradientFill,
                pointBorderColor: chartColor,
                pointBackgroundColor: "#1e3d60",
                pointHoverBackgroundColor: "#1e3d60",
                pointHoverBorderColor: chartColor,
                pointBorderWidth: 1,
                pointHoverRadius: 7,
                pointHoverBorderWidth: 2,
                pointRadius: 5,
                fill: true,
                borderWidth: 2,
                data: valores,
              },
            ],
          },
          options: {
            responsive: true,
            layout: {
              padding: {
                left: 20,
                right: 20,
                top: 0,
                bottom: 0,
              },
            },
            maintainAspectRatio: false,
            tooltips: {
              backgroundColor: "#fff",
              titleFontColor: "#333",
              bodyFontColor: "#666",
              bodySpacing: 4,
              xPadding: 12,
            },
            legend: {
              position: "bottom",
              fillStyle: "#FFF",
              display: false,
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    fontColor: "rgba(255,255,255,0.4)",
                    fontStyle: "bold",
                    beginAtZero: true,
                    maxTicksLimit: 5,
                    padding: 10,
                  },
                  gridLines: {
                    drawTicks: true,
                    drawBorder: false,
                    display: true,
                    color: "rgba(255,255,255,0.1)",
                    zeroLineColor: "transparent",
                  },
                },
              ],
              xAxes: [
                {
                  gridLines: {
                    zeroLineColor: "transparent",
                    display: false,
                  },
                  ticks: {
                    padding: 10,
                    fontColor: "rgba(255,255,255,0.4)",
                    fontStyle: "bold",
                  },
                },
              ],
            },
          },
        });
      },
      async inicio() {
        let json = {
          cliente: JSON.parse(localStorage.getItem("lc_Dashboard_Login"))
            .cliente,
        };
        let res = await api.listarLeads(json);
        var dataAtual = new Date();
        var anoAtual = dataAtual.getFullYear();
        let arrayMeses = res.reduce((acc, obj) => {
          let ano = parseInt(obj.dt_created.substr(0, 4));
          let mes = parseInt(obj.dt_created.substr(5, 2)) - 1;
          if (ano === anoAtual) {
            acc[mes] = (acc[mes] || 0) + 1;
          }
          return acc;
        }, new Array(12).fill(0));
        this.criarGrafico(this.headerChartID, arrayMeses);
      },
    },
    async mounted() {
      await this.inicio();
    },
  };
</script>

<style scoped></style>
