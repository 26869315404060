<template>
  <div v-if="rendered">
    <div class="row">
      <div class="col-md-12">
        <vnud-card>
          <template v-slot:header>
            <div class="row">
              <div class="col-4">
                <h4 class="card-title">Consultores</h4>
              </div>
            </div>
          </template>
          <vnud-card>
            <div>
              <p>Adicionar Consultor</p>
            </div>
            <div class="row">
              <div class="col-4 m-0Input">
                <fg-input
                  type="email"
                  placeholder="Email"
                  style="margin: 0 !important"
                  v-model="consultor.email"
                >
                </fg-input>
              </div>
              <div class="col-4 m-0Input">
                <el-select
                  class="select-primary"
                  size="large"
                  placeholder="Produto"
                  v-model="consultor.produto"
                >
                  <el-option
                    v-for="option in selectProdutos"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="col-4 m-0Input">
                <n-button
                  style="margin: 0px !important"
                  class="black-button"
                  @click="adicionarConsultor()"
                >
                  <i class="fa fa-cloud-download"></i> Adicionar Consultor
                </n-button>
              </div>
            </div>
          </vnud-card>
          <div class="table-responsive">
            <el-table stripe style="width: 100%" :data="queriedData">
              <el-table-column fixed="right" label="Email">
                <template v-slot:default="props">
                  <div class="table-actions">
                    <p>{{ props.row.email }}</p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="Produto">
                <template v-slot:default="props">
                  <div class="table-actions">
                    <el-select
                      class="select-primary"
                      size="large"
                      placeholder="Produto"
                      v-model="props.row.produto"
                      @change="alterarProduto(props.row)"
                    >
                      <el-option
                        v-for="option in selectProdutos"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="Ações">
                <template v-slot:default="props">
                  <div class="table-actions">
                    <n-button
                      @click="AlterarFerias(props.row)"
                      :class="
                        props.row.ausente == 1 ? 'green-button' : 'black-button'
                      "
                      size="sm"
                      round
                      icon
                    >
                      <i class="fa fa-plane"></i>
                    </n-button>
                    <n-button
                      @click="apagarConsultor(props.row)"
                      class="red-button"
                      size="sm"
                      round
                      icon
                    >
                      <i class="fa fa-trash"></i>
                    </n-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
            <div
              class="
                col-12
                d-flex
                justify-content-center justify-content-sm-between
                flex-wrap
              "
            >
              <div class="">
                <p class="card-category">
                  Mostrando <span class="boldNumbers">{{ from + 1 }}</span> até
                  <span class="boldNumbers">{{ to }}</span> de
                  <span class="boldNumbers">{{ total }}</span> leads.
                </p>
              </div>
              <n-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </n-pagination>
            </div>
          </template>
        </vnud-card>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    VnudCard,
    Table as NTable,
    AnimatedNumber,
    Progress as NProgress,
    AsyncWorldMap,
    Collapse,
    CollapseItem,
    Pagination as NPagination,
  } from "@/components";
  import api from "@/api/index";
  import Swal from "sweetalert2";
  import {
    ElTable,
    ElTableColumn,
    ElSelect,
    ElOption,
    ElTimeSelect,
    ElDatePicker,
  } from "element-plus";
  export default {
    components: {
      VnudCard,
      NTable,
      AnimatedNumber,
      NProgress,
      AsyncWorldMap,
      NPagination,
      [ElSelect.name]: ElSelect,
      [ElOption.name]: ElOption,
      [ElTable.name]: ElTable,
      [ElTableColumn.name]: ElTableColumn,
      Collapse,
      CollapseItem,
      [ElDatePicker.name]: ElDatePicker,
      [ElTimeSelect.name]: ElTimeSelect,
    },
    data() {
      return {
        consultor: {
          email: "",
          produto: "",
        },
        pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0,
        },
        selectProdutos: [
          {
            value: "credito_habitacao",
            label: " Crédito Habitação",
          },
          {
            value: "credito_consolidado",
            label: " Crédito Consolidado",
          },
        ],
        searchQuery: "",
        propsToSearch: ["produto"],
        tableColumns: [
          {
            prop: "email",
            label: "Consultor",
          },
          {
            prop: "produto",
            label: "Produto",
          },
        ],
        searchedData: [],
        fuseSearch: null,
        rendered: false,
        leads: {
          conversoes: 0,
          numero: 0,
          mes: 0,
          organicas: 0,
        },
        dataTable: [],
        dateTimePickerDe: "",
        dateTimePickerAte: "",
      };
    },
    methods: {
      async inicio() {
        let res = await api.listarConsultores();
        // console.log(res);
        this.dataTable = res.sort((a, b) => {
          return a.id > b.id ? -1 : 1;
        });

        this.rendered = true;
      },
      async adicionarConsultor() {
        var json = {
          ...this.consultor,
        };
        var res = await api.adicionarConsultor(json);
        console.log(res);
        this.inicio();
      },
      async alterarProduto(array) {
        var json = {
          id: array.id,
          produto: array.produto,
        };
        var res = await api.atualizarProdutoConsultor(json);
        console.log(res);
      },
      async AlterarFerias(valor) {
        if (valor.ausente == "1") {
          valor.ausente = "0";
        } else {
          valor.ausente = "1";
        }
        var json = {
          id: valor.id,
          ausente: valor.ausente,
        };
        var res = await api.atualizarFeriasConsultor(json);
        console.log(res);
      },
      async apagarConsultor(array) {
        var _this = this;
        const swalWithBootstrapButtons4 = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });

        swalWithBootstrapButtons4
          .fire({
            title: "Cuidado Beatriz",
            text: ``,
            confirmButtonText: "Apagar",
            cancelButtonText: "Cancelar",
            showCancelButton: true,
          })
          .then(async (result) => {
            if (result.value) {
              var json = {
                id: array.id,
              };
              var res = await api.apagarConsultor(json);
              console.log(res);
              _this.inicio();
            }
          });
        // const swalWithBootstrapButtons = Swal.mixin({
        //   customClass: {
        //     confirmButton: "btn btn-success",
        //     cancelButton: "btn btn-danger",
        //   },
        //   buttonsStyling: false,
        // });
        // swalWithBootstrapButtons
        //   .fire({
        //     title: "Create an Event",
        //     html: `<div class="form-group">
        //       <input type="text" id="vnud-input" class="form-control">
        //       </div>`,
        //     showCancelButton: true,
        //   })
        //   .then(() => {
        //     var json = {
        //       id: array.id,
        //     };
        //     console.log(json);
        //     // var res = await api.atualizarFeriasConsultor(json);
        //     // console.log(res);
        //   });
      },
    },
    mounted() {
      this.inicio();
    },
    computed: {
      pagedData() {
        return this.dataTable.slice(this.from, this.to);
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData() {
        if (!this.searchQuery) {
          return this.pagedData;
        }
        let result = this.dataTable.filter((row) => {
          let isIncluded = false;
          for (let key of this.propsToSearch) {
            let rowValue = row[key].toString();
            if (rowValue.includes && rowValue.includes(this.searchQuery)) {
              isIncluded = true;
            }
          }
          return isIncluded;
        });
        console.log(result);
        return result.slice(this.from, this.to);
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      },
      total() {
        return this.searchedData.length > 0
          ? this.searchedData.length
          : this.dataTable.length;
      },
    },
  };
</script>
<style>
  .red-button {
    background: red !important;
  }
  .green-button {
    background: green !important;
  }
  .select-primary.el-select .el-input .el-input__inner {
    background-color: #000 !important;
  }
  .select-primary.el-select-dropdown__item.selected {
    color: #000 !important;
  }
  h2#swal2-title {
    padding-top: 2rem;
  }
  .m-0Input input {
    margin: 0 !important;
  }
</style>
