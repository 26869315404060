<template>
  <Form @submit="onSubmit" :validation-schema="schema">
    <div class="col-md-4 ml-auto mr-auto">
      <vnud-card class="card-login card-plain">
        <template v-slot:header>
          <div>
            <div class="logo-container">
              <img src="img/now-logo.png" alt="" />
            </div>
          </div>
        </template>

        <div>
          <vee-input
            name="email"
            type="text"
            inputClasses="no-border form-control-lg"
            placeholder="Email"
            addon-left-icon="now-ui-icons ui-1_email-85"
          />

          <vee-input
            name="password"
            type="password"
            inputClasses="no-border form-control-lg"
            placeholder="Password"
            addon-left-icon="now-ui-icons ui-1_lock-circle-open"
          />
        </div>

        <template v-slot:footer>
          <div>
            <n-button
              class="white-button"
              native-type="submit"
              type="primary"
              round
              block
            >
              Autenticar
            </n-button>
          </div>
        </template>
        <div class="row" v-if="erro">
          <div class="col">
            <span style="font-weight: 500 !important; color: red !important">{{
              mensagem_erro
            }}</span>
          </div>
        </div>
      </vnud-card>
    </div>
  </Form>
</template>
<script>
  import { VeeInput } from "@/components";
  import { Form } from "vee-validate";
  import * as Yup from "yup";
  import api from "@/api/index";
  import { ref } from "vue";
  export default {
    name: "vnud-login",
    components: {
      VeeInput,
      Form,
    },
    // data() {
    //   return {
    //     erro: false,
    //     mensagem_erro: "As suas credenciais de login não coincidem",
    //   };
    // },
    setup() {
      const erro = ref(false);
      async function onSubmit(values) {
        console.log(values);
        let res = await api.login(values);
        // res = Object.values(res);
        if (res.user_id) {
          localStorage.setItem("lc_Dashboard_Login", JSON.stringify(res));
          location.replace("/");
          erro.value = false;
        } else {
          console.log(this);
          // this.erro = true;
          erro.value = true;
        }
        // console.log(res);
      }

      const schema = Yup.object().shape({
        email: Yup.string().email().required().label("The Email"),
        password: Yup.string().min(6).required().label("The Password"),
      });

      return {
        onSubmit,
        schema,
        erro,
        mensagem_erro: "As suas credenciais de login não coincidem",
      };
    },
  };
</script>
<style>
  .navbar-nav .nav-item p {
    line-height: inherit;
    margin-left: 5px;
  }
</style>
